import React from "react";
import { Row, Col, Image, Button } from "antd";
import styles from "./masalaWhatWeDo.module.scss";
import { useNavigate } from "react-router";

const MasalaWhatWeDo = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log("Go To Millets Hotels");
    navigate("/");
  }
  return (
    <div className={styles.whatWeDoContainer}>
      <h2> Millets - The Ancient Grains of India </h2>
      <Row className={styles.cardContainer}>
        <Col span={7}>
          {/* <Image
            src="/assets/images/group-6058@2x.png"
            preview={false}
            alt="TECH SAVY TEAM"
          />
          */}
          <h2> Barnyard Millet (குதிரைவாலி) </h2>
          <ul className={styles.footerList}> <br />
            <li> High Protein </li>
            <li> Rich in Vitamin B-Complex </li>
            <li> Accelerates Weight Loss </li>
          </ul>
          {/* <h2>TECH SAVY TEAM</h2>
          <p style={{ textAlign:"justify" }}>
            U2BT remain updated with latest in technologies and constantly find
            new ways to simply work and maximize productivity.
          </p> */}
        </Col>
        <Col span={7}>
          {/*<Image
            src="/assets/images/group-6058-1@2x.png"
            preview={false}
            alt="TECH SAVY TEAM"
          /> */}
          <h2> Kodo Millet <br /> (வரகு) </h2>
          <ul className={styles.footerList}> <br />
            <li> Reduces Blood Pressure </li>
            <li> Manages body weight </li>
            <li> Lowers Cancer Risk </li>
          </ul>
          {/*
          <h2>CONTINIUOUS LEARNING</h2>
          <p style={{ textAlign:"justify" }}>
            To succeed and stay ahead of the crowd our teach leads and project
            heads make sure that we have a healthy learning environment.
          </p> */}
        </Col>
        <Col span={7} >
          {/* <Image
            src="/assets/images/Group 6058.png"
            preview={false}
            alt="TECH SAVY TEAM"
          /> */}
          <h2> Proso Millet (பனிவரகு) </h2>
          <ul className={styles.footerList}> <br />
            <li> Reduces risk of type-2 diabetes </li>
            <li> Supports in new cell building </li>
            <li> Reduce cholesterol levels </li>
          </ul>
          {/*
          <h2>CLIENT RELATIONSHIP</h2>
          <p style={{ textAlign:"justify" }}>
            Our clients mean a world to us, and this has been to foundation of
            success, as we are available when they need us the most.
          </p>
        */}
        </Col>
        <div className={styles.whatWeDoContainer}><br />
          {/* <h2>What we well known for </h2> <br/> */}
          <Row className={styles.cardContainer}>
            <Col span={7}>
              {/* <Image
            src="/assets/images/group-6058@2x.png"
            preview={false}
            alt="TECH SAVY TEAM"
          /> */}
              <h2> Pearl Millet <br /> (கம்பு) </h2>
              <ul className={styles.footerList}> <br />
                <li> Good for diabetic patients </li>
                <li> Good for heart </li>
                <li> Makes bones stronger </li>
              </ul>
            </Col>
            <Col span={7}>
              {/* <Image
            src="/assets/images/group-6058-1@2x.png"
            preview={false}
            alt="TECH SAVY TEAM"
          /> */}
              <h2> Finger Millet (கேழ்வரகு) </h2>
              <ul className={styles.footerList}> <br />
                <li> Help fight anemia </li>
                <li> Eradicates malnutrition </li>
                <li> Controls Blood Sugar </li>
              </ul>
            </Col>
            <Col span={7}>
              {/* <Image
            src="/assets/images/Group 6058.png"
            preview={false}
            alt="TECH SAVY TEAM"
          /> */}
              <h2> Sorghum Millet (சோளம்) </h2>
              <ul className={styles.footerList}>  <br />
                <li> Good for Heart </li>
                <li> Manages weight </li>
                <li> Fights arthritis </li>
              </ul>
            </Col>
          </Row><br /> <br /> <br />
          <h2> Planning for a party? </h2> <br />
          <p className={styles.subTitle}>
            Leave your worries to U2 Millet Hotel; Our Premium Catering Service gives the perfect plan with its
            traditional menu features some of the most loved and the most novel options for you to gorge on.
          </p> <br />
          <span className={styles.millettxt}>
            <p>Click Here To Try Our New Healthy Millet Food</p>
          </span>
          <Button type="link" href={"https://u2millethotel.com"} className={styles.milletbtn}>U2 Millet Hotel</Button>
        </div>
      </Row>
    </div>
  );
};

export default MasalaWhatWeDo;

/*
<p className={styles.subTitle}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s 
      </p>
      <Button>Read More</Button>
      <Button>Read More</Button>
      <Button>Read More</Button>
*/
