import React from "react";
import { Route, Routes } from "react-router";
import BaseLayout from "./Component/BaseLayout/BaseLayout";
import Home from "./Container/Home/Home";


// import 'antd/dist/reset.css'
function App() {
  return (
    <BaseLayout>
      <Routes>
        <Route path="/" element={<Home />} /> 
      </Routes>
    </BaseLayout>
  );
}

export default App;
