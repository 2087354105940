import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Hero.module.scss";
const Hero = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <BsFillArrowLeftCircleFill />,
    // prevArrow: <BsFillArrowRightCircleFill />,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       centerMode: false,
    //       dots: true,
    //       arrows: false,
    //     },
    //   },
    //   {
    //     breakpoint: 576,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       centerMode: false,
    //       dots: true,
    //       arrows: false,
    //     },
    //   },
    // ],
  };

  const data = {
    contentData: [
      {
        heading: "Innovation, Creative Thinking and Value Delivery",
        title: "Lorem Ipsum is simply dummy text.",
        content:
          "We provide full transparency of development processes,so you can staty on top of your business needs",
        btnLabel: "Read More",
        imgSrc: "/assets/images/web-design-1134-77.jpg_1.jpg",
        //imgSrc1: "/assets/images/web-design-concept-with-drawings_1134-77.jpg.webp",
        //imgSrc2: "/assets/images/U2BT.png",
        //imgSrc: "/assets/images/u2 vc image.PNG",
        //imgSrc1: "/assets/images/u2 vc image.PNG",
        //imgSrc2: "/assets/images/U2BT.png",
        displaySingle: true,
      },
      {
        heading: "Move with the TIMES",
        title: "Lorem Ipsum is simply dummy text.",
        content:
          "when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
        btnLabel: "Read More",
        imgSrc: "/assets/images/u2_vc_image_1.png",
        //imgSrc: "/assets/images/web-design-concept-with-drawings_1134-77.jpg.webp",
        //imgSrc1: "/assets/images/web-design-concept-with-drawings_1134-77.jpg.webp",
        //imgSrc2: "/assets/images/U2BT.png",
        //imgSrc1: "/assets/images/u2 vc image.PNG",
        //imgSrc2: "/assets/images/U2BT.png",
        displaySingle: true,
      },
      {
        heading: "",
        title: "Lorem Ipsum is simply dummy text.",
        content:
          "when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
        btnLabel: "Read More",
        imgSrc: "/assets/images/UI_photo_1.jpg",  // "/assets/images/Group 6268.png",
        //imgSrc1: "/assets/images/u2 vc image.PNG",
        //imgSrc2: "/assets/images/U2BT.png",
        displaySingle: true,
      },
    ],
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.bgImg}>
        <div className={styles.contentWrapper}>
        
          <div className={styles.sliderWrapper}>
            <Slider {...settings} className={styles.heroSlider}>
              {data.contentData.map((item, idx) => (
                <div className={styles.heroDetailsWrapper} key={idx}>
                  {item.displaySingle ? (
                    <>
                    <h2>{item.heading}</h2>
                    <div className={styles.content}>
                    <div className={styles.imgWrapper}>
                      <img
                        src={item.imgSrc}
                        alt={item.heading}
                        width={"95%"}
                        height={"30%"}
                        style={
                          {
                            position:"relative",
                            left:"2.5%",
                            right:"2%",
                          }
                      }
                      />
                    </div>
                    </div>
                    </>
                  ) : (
                    <>
                    <div className={styles.content}>
                    <div className={styles.imgWrapper}>
                      <img
                        src={item.imgSrc}
                        alt={item.heading}
                        width={"140%"}
                        height={"100%"}
                        style={
                          {
                            position:"relative",
                            left:"-4%",
                          }
                      }
                      />
                    </div>
                    <div className={styles.imgWrapper}>
                    <img
                        src={item.imgSrc1}
                        alt={item.heading}
                        width={"90%"}
                        height={"100%"}
                        style={
                          { 
                            marginLeft: "6%",
                            position: "relative",
                            right: "4%",
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.imgWrapper}>
                    <img
                        src={item.imgSrc2}
                        alt={item.heading}
                        width={"10%"}
                        style={
                          { 
                            position:"relative",
                            left:"4%",
                            marginTop: "-15%"
                          //marginLeft: "15%", 
                          }
                        }
                      />
                    </div>
                    </>
                  )}
                  
                </div>
              ))}
            </Slider>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Hero;

/*

<img src={"/assets/images/Group 5972.png"} alt="logo" />
 <h2>U2BT provides full transparency of development processes,so you can staty on top of your business needs</h2>
 <h2>Innovation, Creative Thinking and Value Delivery </h2>
*/