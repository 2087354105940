import React from "react";
import styles from "./hotelAbout.module.scss";

const AboutUsHotel = () => {
  const data = {
    heading: "ABOUT US",
    
    btnLabel: "Read More",
    btnLink: "/hello",
  };
  return (
    <section className={styles.wrapper} id="about-us">
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <h4>{data.heading}</h4>
          <h2>{data.title}</h2>
          <p>Many people today do not eat the food; rather, the food eats them. That’s why everything from 
             Idly to Biriyani to Noodles & pastas is made of <b>MILLETS</b>. We, <b>U2 MILLET HOTEL</b> is 
             proud to say that we make healthy foods and includes <b>MILLETS</b> in everything we make. 
             The restaurant offers breakfast, lunch, and dinner. Our concept is that every dish that can be 
             made with rice and wheat can also be prepared with millets. The restaurant is already catering 
             millet-based food for events and functions. We are also looking forward to setting up cloud kitchens 
             for preparing millet-based snacks and selling them across the globe.</p>
          <div className={styles.btnWrapper}> 
          </div>
        </div>
        <div className={styles.imgWrapper}>
          <img
            src={"/assets/images/Watermark_aboutus.png"}
            alt="about-us"
            width={800}
            height={700}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUsHotel;

/*

  <button className={styles.btn}>{data.btnLabel}</button>
    <img src={"/assets/images/Group 5985.svg"} alt="play-icon" />
*/