import { Tabs } from "antd";
//import { Slider } from "antd";
import React from "react";
import styles from "./Service.module.scss";

const OurServices = () => {
  /*const settings = 
  {
    dots:"true",
    infinite: "true",
    slidestoshow: 2,
    slidestoscroll: 2,
    arrows: true,
    autoPlay: false,
    autoplayspeed: 3000,
    centerpadding: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: 
        {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: 
        {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: 
        {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ], 
  };
*/
  const data = {
    heading: "Our Services",
    contentData: [
      {
        id: 1,
        label: "Product Development",
        title: "Product Development Using MVP",
        list1: "Web Applications",
        list2: "Mobile Applications",
        imgSrc: "/assets/images/web development (1).jpg",  //"/assets/images/rectangle-3020@1x.png",
      },
      {
        id: 2,
        label: "API Development",
        //title: "API Development",
        //imgSrc: "/assets/images/rectangle-3020@1x.png",
        imgSrc: "assets/images/API-Development-Guide (2) (2) (1).png", //"/assets/images/API-Development-Guide (2) (3).png",
        //imgSrc1: "/assets/images/app-development-illustration_52683-47931.jpg.webp",
      },
      {
        id: 3,
        label: "Cloud Management",
        //title: "Cloud Management",
        
        list1: "Cloud Managed Service",
        list2: "Devops",
        list3: "Cloud Migration",
        imgSrc: "/assets/images/software-integration-concept-illustration_114360-7277.jpg.jpg",
      },
      {
        id: 4,
        label: "Third Party Integration",
        //title: "Third Party Integration",
        
        list1: "Twillo",
        list2: "FireBase",
        imgSrc: "/assets/images/ClipartKey_671704 (4) (1).png", //"/assets/images/ClipartKey_671704 (4).png",
      },
    ],
  };

  return (
    <section className={styles.wrapper} id="services">
      <div className={styles.imgBg}>
        <img src="/assets/images/top-wave.png" alt="bg" />
      </div>
      <div className={styles.logo1}>
        <img src="/assets/images/tag.png" alt="bg" />
      </div>
      <div className={styles.logo2}>
        <img src="/assets/images/group-1@2x.png" alt="bg" />
      </div>
      <div className={styles.img1Container}>
        <div className={styles.contentWrapper}>
          <h1>{data.heading}</h1>
          <h4>{data.title}</h4>
          <div className={styles.tabsWrapper}>
            <Tabs className={styles.teamsSlider}
              defaultActiveKey="0"
              centered
              items={data.contentData.map((item) => {
                return {
                  label: item.label,
                  key: item.id,
                  children: (
                    <section className={styles.content}>
                      <div className={styles.details}>
                        <h3>{item.title}</h3>
                        <p>{item.content}</p>
                        <h4>{item.list1}</h4>
                        <h4>{item.list2}</h4>
                        <h4>{item.list3}</h4> 
                      </div>
                      <div className={styles.imgWrapper}>
                        <img src={item.imgSrc} alt="card-img" width={"100%"} height={"80%"}
                          style={{ borderRadius: "25px", marginLeft:"-5%" }}
                        />
                      </div>
                    </section>
                  ),
                };
              })}
            />
          </div>
        </div>
      </div>
      <div className={styles.imgBgBottom}>
        <img src="/assets/images/bottom-wave.png" alt="bg" />
      </div>
    </section>
  );
};

export default OurServices;
