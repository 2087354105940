import React from "react";
import { Row, Col, Image, Button } from "antd";
import styles from "./whatWeDo.module.scss";

const WhatWeDo = () => {
  return (
    <div className={styles.whatWeDoContainer}>
      <h2>What we do</h2>
      <Row className={styles.cardContainer}>
        <Col span={7}>
          <Image
            src="/assets/images/group-6058@2x.png"
            preview={false}
            alt="TECH SAVY TEAM"
          />
          <h2>TECH SAVY TEAM</h2>
          <p style={{ textAlign:"justify" }}>
            U2BT remain updated with latest in technologies and constantly find
            new ways to simply work and maximize productivity.
          </p>
        </Col>
        <Col span={8} >
          <Image
            src="/assets/images/group-6058-1@2x.png"
            preview={false}
            alt="TECH SAVY TEAM"
          />
          <h2>CONTINUOUS LEARNING</h2>
          <p style={{ textAlign:"justify" }}>
            To succeed and stay ahead of the crowd our teach leads and project
            heads make sure that we have a healthy learning environment.
          </p>
        </Col>
        <Col span={7} >
          <Image
            src="/assets/images/Group 6058.png"
            preview={false}
            alt="TECH SAVY TEAM"
          />
          <h2>CLIENT RELATIONSHIP</h2>
          <p style={{ textAlign:"justify" }}>
            Our clients mean a world to us, and this has been to foundation of
            success, as we are available when they need us the most.
          </p>
        </Col>
        <div className={styles.whatWeDoContainer} ><br/>
        <h2>What we well known for </h2> <br/>
        <Row className={styles.cardContainer}>
        <Col span={7}>
          <Image
            src="/assets/images/group-6058@2x.png"
            preview={false}
            alt="TECH SAVY TEAM"
          />
          <ul className={styles.footerList}> <br/>
              <li>Ethical process of development and serve you with responsive products</li>
              <li>Provides the value of money by tailoring your project as per your idea and visualization </li>
              <li>On-time delivery of your project </li>
          </ul>
        </Col>
        <Col span={8}>
          <Image
            src="/assets/images/group-6058-1@2x.png"
            preview={false}
            alt="TECH SAVY TEAM"
          />
          <ul className={styles.footerList}> <br/>
              <li>We maintain the confidentiality and security of your idea </li>
              <li>We cost-effectively help you</li>
              <li>We also provide 24*7 assistance to our clients</li>
          </ul>
        </Col>
        <Col span={7}>
          <Image
            src="/assets/images/Group 6058.png"
            preview={false}
            alt="TECH SAVY TEAM"
          />
          <ul className={styles.footerList}>  <br />
              <li>Building a strong relationship rather than making money </li>
              <li>Equipped with the latest technique we help you in getting the right manpower for all your needs</li>
          </ul>
        </Col>
        </Row><br/> <br/> <br/>
        <h2> Why choose us? </h2> <br/>
        <p className={styles.subTitle}>
          Looking out for the best developers that can handle your dream project like an expert 
          then U2BT is the perfect place to consult with. We have a team of highly dedicated 
          developers that are skilled and efficient in handling projects with any type of complexity 
          and requirement. 
        </p> <br/>
        </div>
      </Row>
    </div>
  );
};

export default WhatWeDo;

/*
<p className={styles.subTitle}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s 
      </p>
      <Button>Read More</Button>
      <Button>Read More</Button>
      <Button>Read More</Button>
*/
