import React from "react";
import styles from "./masalaAbout.module.scss";

const AboutUsMasala = () => {
  const data = {
    heading: "ABOUT US",
    btnLabel: "Read More",
    btnLink: "/hello",
  };
  return (
    <section className={styles.wrapper} id="about-us">
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <h4>{data.heading}</h4>
          <h2>{data.title}</h2>
          <p>Food not only creates connection it is also the most nurturing gift you can offer.  
            <b> The Best MASALAS too</b>. Welcome to U2 Masalas, where we believe that authentic Indian 
            flavours are made with love and care. Our 100% fresh, natural and homemade Masalas 
            are made with healthy & loving recipe and most of the ingredients are directly sourced 
            from the farm. We vow to deliver high-quality products with no added preservatives, 
            artificial colours, and chemicals, ensuring our customers get the best products. We 
            always strive to work committed towards better formulations ensuring a <b>‘Healthy Feast’</b>
            for our valuable customers. We can't wait to share our love for Indian cuisine with you!</p>
          <div className={styles.btnWrapper}> 
          </div>
        </div>
        <div className={styles.imgWrapper}>
          <img
            src={"/assets/images/aboutusmasala1.PNG"} // /assets/images/Watermark_aboutus.png "/assets/images/masalacollage.jpg"
            alt="about-us"
            width={800}
            height={700}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUsMasala;

/*

  <button className={styles.btn}>{data.btnLabel}</button>
    <img src={"/assets/images/Group 5985.svg"} alt="play-icon" />
*/