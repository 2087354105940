import React from "react";
import Slider from "react-slick";
import styles from "./hotelTeams.module.scss";
import { Modal } from "antd";
import { useState } from "react";

const TeamsHotel = () => 
{
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: 
        {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: 
        {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: 
        {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ], 
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => 
  {
    setIsModalOpen(true);
  };
  const handleOk = () => 
  {
    setIsModalOpen(false);
  };
  const handleCancel = () => 
  {
    setIsModalOpen(false);
  };
  const data = {
    heading: "We Serve With LOVE",
    title:
      "தானிய நல் உணவக விருந்து",
    cards: [
      {
        //name: " Corey Anderson",
        position: "Chappathi",
        imgSrc: "/assets/images/Watermark_1677208936543.png",
      },
      {
        //name: " Claire Divas",
        position: "Ghee Roast",
        imgSrc: "/assets/images/Watermark_1677208853812.png",
      },
      {
        //name: " Isabella Croline",
        position: "Puli Sadham",
        imgSrc: "/assets/images/Watermark_1677208563772.png",
      },
      {
        //name: " Jullia Siger",
        position: "Gobi 65",
        imgSrc: "/assets/images/Watermark_1677208801465.png",
      },
      {
        //name: " Corey Anderson",
        position: "Mushroom Briyani",
        imgSrc: "/assets/images/Watermark_1677208622195.png", 
      },
      {
        //name: " Claire Divas",
        position: "Wheat Porata",
        imgSrc: "/assets/images/Watermark_1677208300289.png",
      },
      {
        //name: " Isabella Croline",
        position: "Kammanga Kull",
        imgSrc: "/assets/images/Watermark_1677208720765.png",
      },
      {
        //name: " Jullia Siger",
        position: "Puttu",
        imgSrc: "/assets/images/Watermark_1677208534812.png",
      },
      {
        //name: " Corey Anderson",
        position: "Paniyaram",
        imgSrc: "/assets/images/Watermark_1677208597769.png",
      },
      {
        //name: " Claire Divas",
        position: "Lemon Rice",
        imgSrc: "/assets/images/Watermark_1677208662550.png",
      },
      {
        //name: " Isabella Croline",
        position: "Curd Rice",
        imgSrc: "/assets/images/Watermark_1677208915567.png",
      },
      {
        //name: " Jullia Siger",
        position: "Curd Vadai",
        imgSrc: "/assets/images/Watermark_1677208890718.png",
      },
    ],
  };
  return (
    <section className={styles.wrapper}>
      <div className={styles.imgBg}>
        <img src="/assets/images/top-wave.png" alt="bg" />
      </div>
      <div className={styles.logo1}>
        <img src="/assets/images/group-2@2x.png" alt="bg" />
      </div>
      <div className={styles.logo2}>
        <img src="/assets/images/group-6047@2x.png" alt="bg" />
      </div>
      <div className={styles.logo3}>
        <img src="/assets/images/mask-group-1@2x.png" alt="bg" />
      </div>
      <div className={styles.logo4}>
        <img src="/assets/images/mask-group@1x.png" alt="bg" />
      </div>
      <div className={styles.img1Container}>
        <div className={styles.contentWrapper}>
          <h1>{data.heading}</h1>
          <p className={styles.title}>{data.title}</p>
          <div className={styles.sliderWrapper}>
            <Slider {...settings} className={styles.teamsSlider}>
              {data.cards.map((card, idx) => (
                <div className={styles.content} key={idx} >
                  <div className={`${styles.imgWrapper} imgWrapper`}>
                    <img
                      src={card.imgSrc}
                      alt={card.name}
                    />
                    <div className={styles.details} onClick={showModal}>
                      <h4>{card.name}</h4>
                      <p>{card.position}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <Modal title="Menus" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
              {data.cards.map((card, idx) => (
                  <div className={styles.content} key={idx} >
                    <div className={`${styles.imgWrapper} imgWrapper`}>
                      <img
                        src={card.imgSrc}
                        alt={card.name}
                      />
                      <div className={styles.details} onClick={showModal}>
                        <h4>{card.name}</h4>
                        <p>{card.position}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </Modal>
          </div>
        </div>
      </div>
      <div className={styles.imgBgBottom}>
        <img src="/assets/images/bottom-wave.png" alt="bg" />
      </div>
    </section>
  );
};

export default TeamsHotel;
