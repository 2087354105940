import React from "react";
import styles from "./About.module.scss";

const AboutUs = () => {
  const data = {
    heading: "ABOUT US",
    content:
      "U2 Breakthrough Technology Private Limited, is an emerging IT company in Coimbatore, India, with domain expertise in Data Science, Enterprise Applications Development, Marketing Automation, SaaS Integration and other allied IT services. We have been helping SME’s automate tasks and streamline their operations, thereby effectively reducing costs and improving ROI. U2BT have mastered on how to deliver a seamless customer experience through quick implementation of API’s and Microservices using an Agile + DevOps development strategy bringing down most of development duration to be completed within stipulated time.",
    btnLabel: "Read More",
    btnLink: "/hello",
  };
  return (
    <section className={styles.wrapper} id="about-us">
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <h4>{data.heading}</h4>
          <h2>{data.title}</h2>
          <p>{data.content}</p>
          <div className={styles.btnWrapper}> 
          </div>
        </div>
        <div className={styles.imgWrapper}>
          <img
            src={"/assets/images/Group 6268.png"}
            alt="about-us"
            width={800}
            height={700}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

/*

  <button className={styles.btn}>{data.btnLabel}</button>
    <img src={"/assets/images/Group 5985.svg"} alt="play-icon" />
*/