import { Tabs } from "antd";
//import { Slider } from "antd";
import React from "react";
import styles from "./hotelService.module.scss";

const OurServicesHotel = () => {
  const data = {
    heading: "Our Services",
    contentData: [
      {
        id: 1,
        label: "DINE IN SERVICES",
        content: "Whenever the hunger pangs call, leave it to our chefs at our U2 Millet Hotel to create a menu that dazzles your taste buds. Our kitchens are always sizzling with aroma and flavours to keep you anticipating the next piping hot meal on your table. We offer traditional Tamil food and we have something for everyone. Do sample the house speciality Kuthiravali Biryani & Kamang kool and more when you arrive here.",
        //title: "Product Development Using MVP",
        //list1: "Web Applications",
        //list2: "Mobile Applications",
        imgSrc: "/assets/images/Watermark_1677208521825.png",
        displaySingle: false,
      },
      {
        id: 2,
        label: "CATERING SERVICES",
        content: "U2 Catering Services is loved by happy customers everywhere. We are known not only for the legacy of great taste but in making quality health food in veg that we put on your platter and also for our incredible hospitality & professional catering services of all kinds of event. We have a team of skilled professionals who carry amicable smiles as they carry experience in making your valuable guests feel warm and welcome.",
        //title: "API Development",
        list1: "MARRIAGE CATERING SERVICES",
        list2: "BIRTHDAY PARTY CATERING",
        list3: "OUTDOOR CATERING",
        list4: "ENGAGEMENT PARTY FOOD",
        list5: "HOUSEWARMING PARTY",
        list6: "VALAIKAPPU CATERING",
        list7: "SWEETS AND SAVOUIRIES",
        imgSrc: "/assets/images/Watermark_1677209287128.png",
        displaySingle: true,
      },
      /*{
        id: 3,
        label: "Cloud Management",
        //title: "Cloud Management",
        
        list1: "Cloud Managed Service",
        list2: "Devops",
        list3: "Cloud Migration",
        imgSrc: "/assets/images/software-integration-concept-illustration_114360-7277.jpg.jpg",
      },
      {
        id: 4,
        label: "Third Party Integration",
        //title: "Third Party Integration",
        
        list1: "Twillo",
        list2: "FireBase",
        imgSrc: "/assets/images/ClipartKey_671704 (4) (1).png", //"/assets/images/ClipartKey_671704 (4).png",
      }, */
    ],
  };

  return (
    <section className={styles.wrapper} id="services">
      <div className={styles.imgBg}>
        <img src="/assets/images/top-wave.png" alt="bg" />
      </div>
      <div className={styles.logo1}>
        <img src="/assets/images/tag.png" alt="bg" />
      </div>
      <div className={styles.logo2}>
        <img src="/assets/images/group-1@2x.png" alt="bg" />
      </div>
      <div className={styles.img1Container}>
        <div className={styles.contentWrapper}>
          <h1>{data.heading}</h1>
          <h4>{data.title}</h4>
          <div className={styles.tabsWrapper}>

            <Tabs className={styles.teamsSlider}
              defaultActiveKey="0"
              centered
              items={data.contentData.map((item, idx) => {
                return {
                  label: item.label,
                  key: item.id,
                  children: (
                    <section className={styles.content} key={idx}>
                      {item.displaySingle === true ? (
                        <>
                          <div className={styles.detail}>
                            <h3>{item.title}</h3>
                            <p>{item.content}</p>
                            <li> {item.list1} </li>
                            <li> {item.list2} </li>
                            <li> {item.list3} </li>
                            <li> {item.list4} </li>
                            <li> {item.list5} </li>
                            <li> {item.list6} </li>
                            <li> {item.list7} </li>
                          </div>
                          ,{/*<div className={styles.imgWrapper}>
                                <img src={item.imgSrc} alt="card-img" width={"100%"} height={"80%"}
                                  style={{ borderRadius: "25px", marginLeft: "-5%" }}
                                />
                              </div> */}
                        </>
                      ): (  
                        <>
                            <div className={styles.details}>
                              <h3>{item.title}</h3>
                              <p>{item.content}</p>
                              <span> {item.list1} </span> <br />
                              <span> {item.list2} </span> <br />
                              <span> {item.list3} </span> <br />
                              <span> {item.list4} </span> <br />
                              <span> {item.list5} </span> <br />
                              <span> {item.list6} </span> <br />
                              <span> {item.list7} </span> <br />
                            </div>
                            <div className={styles.imgWrapper}>
                              <img src={item.imgSrc} alt="card-img" width={"100%"} height={"80%"}
                                style={{ borderRadius: "25px", marginLeft: "-5%" }}
                              />
                            </div>
                        </>
                      )}
                      
                </section>
                  ),
                };
              })}
            /> 
          </div>
        </div>
      </div>
      <div className={styles.imgBgBottom}>
        <img src="/assets/images/bottom-wave.png" alt="bg" />
      </div>
    </section>
  );
};

export default OurServicesHotel;
