import React from "react";
import styles from "./styles.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutUs from "../../Component/AboutUs";
import OurServices from "../../Component/OurService";
import Teams from "../../Component/Teams";
import Hero from "../../Component/Hero";
import WhatWeDo from "../../Component/WhatWeDo";
import ContactUs from "../../Component/ContactUs";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import { useState, useEffect } from "react";
import HomeHotel from "./hotelHome";
import HeaderHotel from "../../Component/Header/hotelHeader";
import AboutUsHotel from "../../Component/AboutUs/hotelAboutUs";
import AboutUsMasala from "../../Component/AboutUs/masalaAboutUs";
import OurServicesHotel from "../../Component/OurService/hotelOurservice";
import TeamsHotel from "../../Component/Teams/hotelTeams";
import HeroHotel from "../../Component/Hero/hotelHero";
import WhatWeDoHotel from "../../Component/WhatWeDo/hotelWhatWeDo";
import ContactUsHotel from "../../Component/ContactUs/hotelContactUs";
import FooterHotel from "../../Component/Footer/hotelFooter";
import MasalaHeader from "../../Component/Header/masalaHeader";
import MasalaWhatWeDo from "../../Component/WhatWeDo/masalaWhatWeDo";
import MasalaHero from "../../Component/Hero/masalaHero";
import MasalaTeams from "../../Component/Teams/masalaTeams";
import MasalaFooter from "../../Component/Footer/masalaFooter";
import MasalaContactUs from "../../Component/ContactUs/masalaContactUs";
import MasalaOurServices from "../../Component/OurService/masalaOurservice";
/*import { useNavigate } from "react-router-dom";
import { encrypt } from "n-krypta";
import {Button} from "antd";
import { WhatsAppOutlined } from '@ant-design/icons'; */

const Home = () => {
 
  var u2bt = [];
  var hotels = [];
  var masala = [];
  const [ok, setOk] = useState();

useEffect(() => {
  u2bt = [<Header />, <Hero />, <AboutUs />, <OurServices />, <WhatWeDo />, <Teams />,  <ContactUs />, <Footer />];
  hotels = [<HeaderHotel />, <HeroHotel />, <AboutUsHotel />, <OurServicesHotel />, <WhatWeDoHotel />, <TeamsHotel />, <ContactUsHotel />, <FooterHotel />];
  masala = [<MasalaHeader />, <MasalaHero />, <AboutUsMasala />, <MasalaOurServices />, <MasalaTeams />, <MasalaContactUs />, <MasalaWhatWeDo />, <MasalaFooter />];
  console.log(window.location.hostname);
  if((window.location.hostname === 'localhost'  && window.location.port === '3000') 
  || window.location.hostname === 'breakthroughtech.in'
  || window.location.hostname === 'www.breakthroughtech.in')
  {
      document.title = 'U2BT | Software Solutions';
      console.log("Get U2BT Port");
      const all = u2bt.map((element) => element);
      setOk(all);
      console.log("Successfully Get U2BT Component");
       
  }
  else if ((window.location.hostname === 'localhost'  && window.location.port === '3001') 
  || window.location.hostname === 'u2millethotel.com'
  || window.location.hostname === 'www.u2millethotel.com')
    {
      document.title = 'U2 Millet Hotel';
      console.log("Get U2BT Port");
      const all = hotels.map((element) => element);
      setOk(all);
      console.log("Successfully Get U2Kitchen Component");
      
    }
  else if ((window.location.hostname === 'localhost'  && window.location.port === '3002')
   || window.location.hostname === 'u2masalas.com'
   || window.location.hostname === 'www.u2masalas.com')
    {
      document.title = 'U2 Masala';
      console.log("Get U2BT Port");
      const all = masala.map((element) => element);
      setOk(all);
      console.log("Successfully Get U2Masla Component");
    }
}, []);

  return (
    
    <div className={styles.container}>
      {ok}
    </div>
    
  );
};

export default Home;


/* const navigate = useNavigate();

  const handleClick = (e) =>
  {
    const secretKey = 'key';
    const data = "Hi i need information regarding software development";
    const encryptData = encrypt(data, secretKey);
    console.log(encryptData);
    navigate("/")
  }
  {/*<Hero />
      <AboutUs />
      <OurServices />
      <WhatWeDo />
      <Teams />
      <ContactUs />
      <Button type="primary" block shape="round" htmlType="submit" onClick = { handleClick }> 
        {<WhatsAppOutlined />}
  </Button> */
         
         /*<FloatButton
          shape="circle"
          type="primary"
          style={{
            right: 94,
          }}
        icon={<WhatsAppOutlined />}
        onClick = { handleClick }
        />*/
  
