import React from "react";
import Slider from "react-slick";
import styles from "./Teams.module.scss";

const Teams = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: 
        {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: 
        {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: 
        {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ], 
  };

  const data = {
    heading: "Team",
    title:
      "U2BT have managed to deliver only the best of products & services with an agile, experienced & tech savy team of Developers, Analysists, Consultants and Project Managers. With certified professionals and subject matter experts at the healm, our client finds it a delight to work with U2BT always.",
    cards: [
      {
        //name: " Corey Anderson",
        position: "Project Manager",
        //imgSrc: "/assets/images/rectangle-41-1@2x.png",
        imgSrc: "/assets/images/Project Manager (1).png", //"/assets/images/Project Manager.png"
      },
      {
        //name: " Claire Divas",
        position: "Developer",
        //imgSrc: "/assets/images/rectangle-41-2@2x.png",
        imgSrc: "/assets/images/Developer (5) (1).png", //"/assets/images/Developer (5).png",
      },
      {
        //name: " Isabella Croline",
        position: "Analysists",
        //imgSrc: "/assets/images/rectangle-41-3@2x.png",
        imgSrc: "/assets/images/12643934_5017953 (1).jpg", //"/assets/images/12643934_5017953.jpg"
      },
      {
        //name: " Jullia Siger",
        position: "Consultants",
        //imgSrc: "/assets/images/rectangle-41@2x.png",
        imgSrc: "/assets/images/2575107_250952-P4L7QF-829.jpg",
      },
      {
        //name: " Corey Anderson",
        position: "UX Designer",
        //imgSrc: "/assets/images/rectangle-41-1@2x.png",
        imgSrc: "/assets/images/Project Manager (1).png", //"/assets/images/Project Manager.png"
      },
      {
        //name: " Claire Divas",
        position: "Qualtiy Assurance Engineer",
        //imgSrc: "/assets/images/rectangle-41-2@2x.png",
        imgSrc: "/assets/images/Developer (5) (1).png", //"/assets/images/Developer (5).png",
      },
      {
        //name: " Isabella Croline",
        position: "Product Owner",
        //imgSrc: "/assets/images/rectangle-41-3@2x.png",
        imgSrc: "/assets/images/12643934_5017953 (1).jpg", //"/assets/images/12643934_5017953.jpg"
      },
      {
        //name: " Jullia Siger",
        position: "Scrum Master",
        //imgSrc: "/assets/images/rectangle-41@2x.png",
        imgSrc: "/assets/images/2575107_250952-P4L7QF-829.jpg",
      },
    ],
  };
  return (
    <section className={styles.wrapper}>
      <div className={styles.imgBg}>
        <img src="/assets/images/top-wave.png" alt="bg" />
      </div>
      <div className={styles.logo1}>
        <img src="/assets/images/group-2@2x.png" alt="bg" />
      </div>
      <div className={styles.logo2}>
        <img src="/assets/images/group-6047@2x.png" alt="bg" />
      </div>
      <div className={styles.logo3}>
        <img src="/assets/images/mask-group-1@2x.png" alt="bg" />
      </div>
      <div className={styles.logo4}>
        <img src="/assets/images/mask-group@1x.png" alt="bg" />
      </div>
      <div className={styles.img1Container}>
        <div className={styles.contentWrapper}>
          <h1>{data.heading}</h1>
          <p>{data.title}</p>
          <div className={styles.sliderWrapper}>
            <Slider {...settings} className={styles.teamsSlider}>
              {data.cards.map((card, idx) => (
                <div className={styles.content} key={idx}>
                  <div className={`${styles.imgWrapper} imgWrapper`}>
                    <img
                      src={card.imgSrc}
                      alt={card.name}
                    />
                    <div className={styles.details}>
                      <h4>{card.name}</h4>
                      <p>{card.position}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className={styles.imgBgBottom}>
        <img src="/assets/images/bottom-wave.png" alt="bg" />
      </div>
    </section>
  );
};

export default Teams;
