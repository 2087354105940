import React from "react";
import { useState } from 'react';
import { Col, Form, Input, Row, Image, Select, } from "antd";
import styles from "./contactUs.module.scss";
import { registerUser } from "../../Services/registerAPI";
const { Option } = Select;
const { TextArea } = Input;

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 70 }} >
      <Option value="91">
        {/* <img
          src="/assets/images/rectangle-3025@2x.png"
          alt="Country Flag"
          height={50}
          width={50}
        /> */}
        +91
      </Option>
      <Option value="87">+87</Option>
    </Select>
  </Form.Item>
);

const ContactUs = () => 
{
    const initialValues = { firstname: "", lastname: "", phone: "", email: "", message: "" };
    const [inputValues, setInputValues] = useState(initialValues);
    const [isSubmit, setIsSubmit] = useState(false);
    const [form] = Form.useForm();

    const handleInput = (e) =>
    {
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    }

 /* const onsubmitHandler = (values) => 
    {
      console.log(values);
    }; 
*/

    const onFinishRegister = async (values) =>
      {
          let data = {values};
          console.log('Received values of form: ', data);
          setIsSubmit(true);
          let body = {};
          body.fName = values.firstname;
          body.lName = values.lastname;
          body.phone = values.phone;
          body.email = values.email;
          body.messg = values.message; 
          console.log(body);
          const register = await registerUser(body);
          console.log(register);
          console.log(form.getFieldValue("firstname"));
          form.resetFields();
          form.setFieldValue("firstname","");
          //navigate('/login') 
      };

    const onFinishRegisterFailed = (error) =>
      {
          console.log('Received values of form: ', error);
      };

  return (
    <div className={styles.contactUsContainer} id="contact-us">
      <Row>
        <Col span={24}> <h2>Contact Us</h2> </Col>
      </Row>
      <div className={styles.container}>
          <Row>
            <Col span={2}>
            </Col>
            <Col span={10}>
              <div className={styles.formWrapper}>
                <Form
                  name="basic"
                  form={form}
                  initialValues={
                    {
                      remember: true,
                      prefix: '91',
                    }
                }
                  //onFinish={onsubmitHandler}
                  autoComplete="off"
                  onFinish={ onFinishRegister }
                  onFinishFailed={ onFinishRegisterFailed }
                >
                  <div style={{ marginBottom: "0.8rem" }}>
                    <span>Name</span>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  
                  <Row gutter={[36, 24]} >
                    <Col>
                    <Form.Item
                      className={styles.firstname}
                      name="firstname"
                      hasFeedback 
                      rules=
                      {
                        [
                          { 
                            required: true, 
                            message: "Please Enter First Name" 
                          }, 
                          { whitespace: true },
                          { 
                            min: 3, 
                            message: "Enter first name more than 3 character" 
                          }
                        ]
                      } 
                      
                    >
                      <Input placeholder="First Name"
                        value={ inputValues.firstname } 
                        onChange={ handleInput }
                        
                      />
                      </Form.Item>
                    </Col>
                    <Col>
                    <Form.Item
                        className={styles.lastname}
                        name="lastname" 
                        hasFeedback 
                        rules=
                        {
                          [
                            { 
                              required: true, 
                              message: "Please Enter Last Name" 
                            }, 
                            { whitespace: true },
                            { 
                              min: 1, 
                              message: "Enter last name atleast 1 character" 
                            }
                          ]
                        }
                        
                    >
                      <Input placeholder="Last Name"
                        value={ inputValues.lastname }
                        onChange={ handleInput }
                        //className= {"last"}
                      />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <span style={{ margin: "0rem 0" }} >Mobile</span>
                    <span style={{ color: "red" }}>*</span>
                    <Form.Item
                      className={styles.phone}
                      name="phone" 
                      hasFeedback
                      rules=
                      {
                        [
                          { required: true, message: "Enter Your Mobile Number" },
                          { whitespace: true },
                          { min: 10 },
                          { max: 10 },
                          { type: 'RegExp' },
                          { 
                            pattern: new RegExp(/^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/), 
                            message: 'Invalid Format Entered' 
                          }
                        ]
                      }
                      style={
                        { 
                          marginTop:"7%",
                          marginLeft:"-12%",
                          minWidth:"430px",
                          padding: "8px 4px",
                        }
                      } 
                    >
                    <Input 
                      addonBefore={prefixSelector}
                      style={{ width: "100%" }}
                      placeholder="Phone"
                      value={ inputValues.phone } 
                      onChange={ handleInput }
                    />
                    </Form.Item>
                  </Row>
                  <Row>
                  <span style={{ margin: "0rem 0" }} >Email</span>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Item
                      className={styles.email}
                      name="email" 
                      hasFeedback 
                      rules=
                        {
                          [
                            { 
                              required: true, 
                              message: "Enter Your Email Id" 
                            }, 
                            { whitespace: true },
                            { 
                              type: 'email', 
                              message: 'Invalid Email Provided' 
                            }
                          ]
                        } 
                        style={
                          { 
                            marginTop:"7%",
                            marginLeft:"-11%",
                            marginRight: "-10%",
                            minWidth:"430px",
                            //width: "80%",
                            padding: "8px 4px",
                          }
                        } 
                  >
                    <Input
                      placeholder="Email"
                      value={ inputValues.email } 
                      onChange={ handleInput } 
                    />
                    </Form.Item>
                  </Row>
                  <Row>
                    <span style={{ margin: "0rem 0" }}>Message</span>
                    <span style={{ color: "red" }}>*</span>
                    <Form.Item
                        className={styles.msg}
                        name="message" 
                        hasFeedback 
                        rules=
                        {
                          [
                            { 
                              required: true, 
                              message: "Please Enter Message" 
                            }, 
                            { whitespace: true },
                            { 
                              max: 255, 
                              message: "Enter Data More Than 100 Characters" 
                            }
                          ]
                        }
                        style={
                          { 
                            marginTop:"7%",
                            marginLeft:"-16%",
                            minWidth:"415px",
                            //width: "88%",
                            padding: "8px 4px",
                          }
                        } 
                    >
                    <TextArea rows={5} 
                      placeholder="Message"
                      value={ inputValues.message } 
                      onChange={ handleInput } 
                    />
                    </Form.Item>
                  </Row>
                  <button className={styles.btn}>Send</button>
                </Form>
              </div>
            </Col>
            <Col span={12}>       
              <div className={styles.map} id="location">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.71693621741497!2d76.99962764122839!3d11.078233191605987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f7f26f81f64b%3A0x892eea815a800f6f!2sSri%20Radha%20Towers%20Saravanampatti!5e0!3m2!1sen!2sin!4v1676971938143!5m2!1sen!2sin" width="100" height="100" style="border:0;" allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.71693621741497!2d76.99962764122839!3d11.078233191605987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f7f26f81f64b%3A0x892eea815a800f6f!2sSri%20Radha%20Towers%20Saravanampatti!5e0!3m2!1sen!2sin!4v1676971938143!5m2!1sen!2sin" title="google" width="100%" height="100%"> </iframe>
              </div>
            </Col>
          </Row>
      </div>
    </div>
  );
};

export default ContactUs;

/*

<p className={styles.subTitle}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s
      </p>
*/
