import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Image } from "antd";
import { InstagramOutlined, TwitterOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { GoLocation } from "react-icons/go";
import { ImFacebook } from "react-icons/im";
import { FaLinkedinIn, FaTiktok } from "react-icons/fa";
import styles from "./hotelFooter.module.scss";

const scroll = (id) => {
  const section = document.querySelector(`#${id}`);
  section.scrollIntoView({ behavior: "smooth", block: "start" });
};

const FooterHotel = () => {
  return (
    <>
      <div className={styles.topFooterContainer}>
        <Row className={styles.topFooter}>
          <Col>
            <h2>U2 MILLET HOTEL</h2>
           <span className={styles.cin}> FSSAI: 22422556000303</span>
          </Col>
          
        </Row>
        <Row className={styles.middleFooter} gutter={[0, 32]}>
          <Col xs={24} md={12} lg={9}>
            <h2 style={{marginTop: 0, marginLeft:"-14%" }}>GET THE LATEST FROM MEDIA</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
              }}
            >
              <div className={styles.locationIconBlocks}>
                <Link onClick={() => scroll("location")}>
                  <GoLocation />
                </Link>
              </div>
              <span className={styles.locationBlock}>
                S. F 18, 10, Rathnagiri Road, Villankurichi, Coimbatore 641035, Tamil Nadu, India. 
              </span> 
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
              }}
            >
              <div className={styles.locationIconBlock}>
                <MailOutlined /> 
                </div>
                  <span className={styles.locationInfo}> 
                    <a href="/#" target={"_blank"} rel="noopener noreferrer">sales@u2ck.com</a> 
                  </span>
            </div>
            
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
              }}
            >
              <div className={styles.locationIconBlock}>
                  <PhoneOutlined /> 
                </div>
                  <span className={styles.locationBlocks}> 
                    +91 63856 05666, +91 75400 77752
                  </span>
              </div>

            <div className={styles.iconsWrapper}>
              <a href="https://www.instagram.com/u2cloudkitchen/" target={"_blank"} rel="noopener noreferrer">
                <InstagramOutlined />
              </a>
              <a href="#" target={"_blank"} rel="noopener noreferrer">
                <TwitterOutlined />
              </a>
              <a href="https://www.facebook.com/u2cloudkitchen" target={"_blank"} rel="noopener noreferrer">
                <ImFacebook />
              </a>
              {/* <a href="#" target={"_blank"} rel="noopener noreferrer">
                <FaLinkedinIn />
              </a> */}
              {/* <a href="#" target={"_blank"} rel="noopener noreferrer">
                <FaTiktok />
              </a> */}
            </div>
          </Col>
          <Col xs={24} md={12} lg={5}>
            <h4 className={styles.listTitle}>SERVICES</h4>
            <ul className={styles.footerList}>
              <Link onClick={() => scroll("home")} className={styles.links}> <li>Home</li> </Link>
              <Link onClick={() => scroll("about-us")} className={styles.links}> <li>About Us</li> </Link>
              <Link onClick={() => scroll("services")} className={styles.links}> <li>Services</li> </Link>
              <Link onClick={() => scroll("contact-us")} className={styles.links}> <li>Contact Us</li> </Link>
            </ul>
          </Col>
          
          <Col xs={24} md={12} lg={5}>
            <h4 className={styles.listTitles}>GET SUPPORT</h4>
            <ul className={styles.footerLists}>
              <li>sales@u2ck.com</li>
              <li>support center</li>
            </ul>
          </Col>
          <Col xs={24} md={12} lg={5}>
          <img className={styles.imgWrapper}
            src="/assets/images/u2 logo.PNG"
            alt="error"
            width={322}
            height={322}
          />
          </Col>
        </Row>
      </div>
      <div className={styles.bottomFooterContainer}>
        <Row className={styles.bottomFooter}>
          <Button type="link">Terms & Conditions</Button>
          <Button type="link">Cookie Policy</Button>
          <Button type="link">Privacy Policy</Button>
        </Row>
      </div>
    </>
  );
};

export default FooterHotel;

/*

<Col>
            <Image
              src="/assets/images/google.png"
              preview={false}
              alt="Google Play"
            />
            <Image
              src="/assets/images/apple.png"
              preview={false}
              alt="App Store"
            />
          </Col>

          <Col xs={24} md={12} lg={5}>
            <h4 className={styles.listTitle}>SERVICES</h4>
            <ul className={styles.footerList}>
              <li>Lorem Ipsum</li>
              <li>Lorem Ipsum</li>
              <li>Lorem Ipsum is simply</li>
              <li>Lorem Ipsum</li>
            </ul>
          </Col>
*/