import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { BsGlobe } from "react-icons/bs";
import { Button, Typography, Image, Dropdown, Space, Menu } from "antd";
import styles from "./style.module.scss";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

/*const menu = (
  <Menu>
    <Menu.Item key="EN">EN</Menu.Item>
  </Menu>
); */

const scroll = (id) => {
  const section = document.querySelector(`#${id}`);
  section.scrollIntoView({ behavior: "smooth", block: "start" });
};

const NavLinks = () => (
  <>
    <Link to="/" className={styles.navLink}>
      Home
    </Link>
    <Link onClick={() => scroll("about-us")} className={styles.navLink}>
      About Us
    </Link>
    <Link onClick={() => scroll("services")} className={styles.navLink}>
      Services
    </Link>
    <Link onClick={() => scroll("contact-us")} className={styles.navLink}>
      Contact Us
    </Link>
    <span>
      <Image src="/assets/images/u2 logo.PNG" preview={false} alt="Logo" height={"120%"} width={"30%"} 
        style={{ marginLeft:"385%",  position: "relative", bottom: "30%"  }}/>
    </span>
  </>
);

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <>
      <div className={styles.container} id="home">
        <h1>U2 BREAKTHROUGH TECHNOLOGY</h1>
        <div className={styles.desktopNavLink}>
          <NavLinks />
        </div>
        <Typography className={styles.menuOutlined}>
          {openMenu ? (
            <CloseOutlined onClick={() => setOpenMenu(!openMenu)} />
          ) : (
            <MenuOutlined onClick={() => setOpenMenu(!openMenu)} />
          )}
        </Typography>
      </div>
      {openMenu && (
        <div className={styles.mobileNavLink}>
          <NavLinks />
        </div>
      )}
    </>
  );
};

export default Header;

/*

<Dropdown overlay={menu} className={styles.langMenu}>
      <Space>
        <BsGlobe />
        EN
        <DownOutlined />
      </Space>
    </Dropdown>

  <Image src="/assets/images/U2BT.png" preview={false} alt="Logo" />
*/