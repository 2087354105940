import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./hotelHero.module.scss";
const HeroHotel = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <BsFillArrowLeftCircleFill />,
    // prevArrow: <BsFillArrowRightCircleFill />,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       centerMode: false,
    //       dots: true,
    //       arrows: false,
    //     },
    //   },
    //   {
    //     breakpoint: 576,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       centerMode: false,
    //       dots: true,
    //       arrows: false,
    //     },
    //   },
    // ],
  };

  const data = {
    contentData: [
      {
        heading: "அறுசுவை இது தனி சுவை ", //"Health is the greatest wealth that anyone can possess",
        title: "Lorem Ipsum is simply dummy text.",
        content:
          "We provide full transparency of development processes,so you can staty on top of your business needs",
        btnLabel: "Read More",
        imgSrc:  "/assets/images/Watermark_1_1.png",
        displaySingle: true,
      },
      {
        heading: "Good food is the foundation of genuine happiness",
        title: "Lorem Ipsum is simply dummy text.",
        content:
          "when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
        btnLabel: "Read More",
        imgSrc: "/assets/images/Watermark_2.png",
        displaySingle: true,
      },
      {
        heading: "Enjoy your food enjoy your life",
        title: "Lorem Ipsum is simply dummy text.",
        content:
          "when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
        btnLabel: "Read More",
        imgSrc: "/assets/images/Watermark_3.png",
        displaySingle: true,
      },
    ],
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.bgImg}>
        <div className={styles.contentWrapper}>
        
          <div className={styles.sliderWrapper}>
            <Slider {...settings} className={styles.heroSlider}>
              {data.contentData.map((item, idx) => (
                <div className={styles.heroDetailsWrapper} key={idx}>
                  {item.displaySingle ? (
                    <>
                    <h2>{item.heading}</h2>
                    <div className={styles.content}>
                    <div className={styles.imgWrapper}>
                      <img
                        src={item.imgSrc}
                        alt={item.heading}
                        width={"95%"}
                        height={"30%"}
                        style={
                          {
                            position:"relative",
                            left:"2.5%",
                            right:"2%",
                          }
                      }
                      />
                    </div>
                    </div>
                    </>
                  ) : (
                    <>
                    <div className={styles.content}>
                    <div className={styles.imgWrapper}>
                      <img
                        src={item.imgSrc}
                        alt={item.heading}
                        width={"140%"}
                        height={"100%"}
                        style={
                          {
                            position:"relative",
                            left:"-4%",
                          }
                      }
                      />
                    </div>
                    <div className={styles.imgWrapper}>
                    <img
                        src={item.imgSrc1}
                        alt={item.heading}
                        width={"90%"}
                        height={"100%"}
                        style={
                          { 
                            marginLeft: "6%",
                            position: "relative",
                            right: "4%",
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.imgWrapper}>
                    <img
                        src={item.imgSrc2}
                        alt={item.heading}
                        width={"10%"}
                        style={
                          { 
                            position:"relative",
                            left:"4%",
                            marginTop: "-15%"
                          //marginLeft: "15%", 
                          }
                        }
                      />
                    </div>
                    </>
                  )}
                  
                </div>
              ))}
            </Slider>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default HeroHotel;

/*

<img src={"/assets/images/Group 5972.png"} alt="logo" />
 <h2>U2BT provides full transparency of development processes,so you can staty on top of your business needs</h2>
 <h2>Innovation, Creative Thinking and Value Delivery </h2>


 const Home = () => {
  let hero = <Teams />;
  var hotels = [ ];
  const [appname, setAppname] = useState(hotels);

  useEffect(() => {
    hero = <Hero />;
    hotels = [<Hero />, <AboutUs />, <OurServices />, <WhatWeDo />, <Teams />, <ContactUs />];
    console.log(window.location.hostname)
    if (window.location.hostname === 'localhost')
    {
      console.log("Get Hostname")
      if (window.location.port === '3000')
      {
        console.log("Get Port")
        const all = hotels.map((hotel) => 
          hotel
        );
        setAppname(all);
        console.log("Successfully Done")
      }
      else
      {
        setAppname([]);
        console.log("Failed")
      }
    }
}, []);

  return (
    
    <div className={styles.container}>  
      {appname}  
  </div>
    
  );
};

export default Home;


/*<AboutUs />
      <OurServices />
      <WhatWeDo />
      <Teams />
      <ContactUs />
      <Button type="primary" block shape="round" htmlType="submit" onClick = { handleClick }> 
        {<WhatsAppOutlined />}
  </Button> */
         
         /*<FloatButton
          shape="circle"
          type="primary"
          style={{
            right: 94,
          }}
        icon={<WhatsAppOutlined />}
        onClick = { handleClick }
        />
        
*/